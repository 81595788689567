<script>
    import Layout from "./Layout.svelte";
    import { Container, Row, Col, Button } from "@sveltestrap/sveltestrap";
    import { getContext } from "svelte";
    import { init, __ } from "../i18n";

    export let title;
    export let error;
    
    const { catalog } = getContext("global.stores");
    init($catalog);
</script>

<svelte:head>
    <title>{title}</title>
</svelte:head>

<Layout>
    <local>
        <Container>
            <Row class="code">
                <Col><h1>{error.status}</h1></Col>
            </Row>
            <Row class="message">
                <Col><h4>{error.msg}</h4></Col>
            </Row>
            <Row>
                <a href="/"><Button color="custom" class="my-2">{__("HOME_BTN")}</Button></a>
            </Row>
        </Container>
    </local>
</Layout>

<style>
    :global(body, html) {
        background: none;
        background-color: var(--bg-color);
        text-align: center;
        color: var(--text-color);
    }

    local :global(.code h1) {
        font-size: 12em;
        margin-top: 10%;
    }

    local :global(.message h4) {
        font-weight: 400;
    }

    local :global(button) {
        color: var(--btn-text-color);
        background-color: var(--btn-bg-color);
        border-color: var(--btn-bg-color);
    }

    local :global(button:hover),
    local :global(btn.active) {
        background-color: var(--btn-bg-color) !important;
        border-color: var(--btn-bg-color) !important;
        opacity: 0.9;
        color: var(--btn-text-color) !important;
    }

    local :global(button:active) {
        border-style: inset;
    }
</style>
